// All
@import "spinkit/spinkit";
@import "chart.js/chart";
@import "ladda/ladda";
@import "toastr/toastr";

// AngularJS, AJAX & Static
@import "datatables/datatables";
@import "daterangepicker/daterangepicker";

@import "fullcalendar/fullcalendar";
@import "gauge.js/gaugejs";
@import "ionRangeSlider/ionRangeSlider";
@import "select2/select2";

// Angular 2
@import "angular2-calendar/angular2-calendar";
@import "draggula/draggula";

// ReactJS
@import "react-select/default"
