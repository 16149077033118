@import
  "spinners/1-rotating-plane",
  "spinners/2-double-bounce",
  "spinners/3-wave",
  "spinners/4-wandering-cubes",
  "spinners/5-pulse",
  "spinners/6-chasing-dots",
  "spinners/7-three-bounce",
  "spinners/8-circle",
  "spinners/9-cube-grid",
  "spinners/10-fading-circle",
  "spinners/11-folding-cube";
